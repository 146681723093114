<script setup>
import { usePortfolioStore } from '@/store/index'
import { Screen } from 'quasar'
</script>

<template>
    <!--<q-layout view="lHh Lpr lFf">-->
    <q-layout view="hHh Lpr lff">
        <q-header elevated class="bg-black" v-if="Screen.lt.md">
            <q-toolbar>
                <q-btn flat round dense icon="menu" class="q-mr-sm" @click="toggleSidebar" />

                <q-space />

                <div>
                    <q-carousel
                    v-model="slide"
                    vertical
                    transition-prev="slide-left"
                    transition-next="slide-right"
                    animated
                    height="100%"
                    style="background-color: transparent"
                    >
                        <q-carousel-slide v-for="p in getPageTitles()" :key="p.slideID" class="q-pa-none" :name="p.slideID">
                            <div class="text-h5" style="font-weight: 700; letter-spacing: -0.01562em;">{{ p.title }}</div>
                        </q-carousel-slide>
                    </q-carousel>
                </div>
            </q-toolbar>
        </q-header>

        <q-drawer
        v-model="getDrawerOpenState"
        :breakpoint="0"
        :overlay="Screen.lt.md"
        :width="drawerWidth"
        style="background-color: #000;"
        class="sidebar"
        >
            <div class="sidebar-inner flex column">
                <h1 class="my-name q-ma-none q-px-xl q-pt-xl">Alrex Consus</h1>
                <h2 class="my-title q-ma-none q-px-xl q-pt-sm">Engineer / Developer</h2>
                <div class="my-intro q-px-xl q-pt-xl">I build creative, interactive and pixel-perfect web applications and websites.</div>
                <div class="menu-container q-pt-lg q-mt-xl">
                    <ul class="main-menu q-pa-none q-ma-none q-mx-xl">
                        <li v-for="m in getMenuItems()" class="menu-item" :key="m.id">
                            <a href="#" class="menu-link" :class="(m.id == activeMenu) ? 'active' : ''" @click.prevent="setActive(m.id)">
                                <span>{{ m.name }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="socials">
                    <div class="q-pa-xl text-left">
                        <q-btn dense round flat class="q-mr-sm" padding="10px">
                            <q-icon size="md" name="person_outline" />
                        </q-btn>
                        <q-btn dense round flat class="q-mr-sm" padding="10px">
                            <q-icon size="md" name="public" />
                        </q-btn>
                        <q-btn dense round flat class="q-mr-sm" padding="10px">
                            <q-icon size="md" name="flash_on" />
                        </q-btn>
                        <q-btn dense round flat class="" padding="10px">
                            <q-icon size="md" name="mail_outline" />
                        </q-btn>
                    </div>
                </div>
            </div>
        </q-drawer>

        <q-page-container>
            <div id="page-title-cont">
                <q-carousel
                v-model="slide"
                vertical
                transition-prev="slide-down"
                transition-next="slide-up"
                animated
                height="100%"
                style="background-color: transparent"
                >
                    <q-carousel-slide v-for="p in getPageTitles()" :key="p.slideID" class="q-pa-none" :name="p.slideID">
                        <div class="page-title-wrapper"><h1 class="page-title q-ma-none">{{ p.title }}</h1></div>
                    </q-carousel-slide>
                </q-carousel>

                <div class="menu-toggle-button">
                    <q-btn dense round flat class="q-my-md" padding="10px" @click="toggleSidebar">
                        <q-icon size="lg" :name="getDrawerOpenState ? 'menu_open' : 'menu'" />
                    </q-btn>
                </div>
            </div>
            
            <router-view v-slot="{ Component }">
                <component ref="view" @update-menu-and-page="updateMenuAndPage" :is="Component" />
            </router-view>

        </q-page-container>
    </q-layout>
</template>

<script>
export default {
    created() {
        this.portfolio = usePortfolioStore()
    },
    mounted() {
        //this.getPageTitles()
        this.portfolio.setDrawerOpenState( !Screen.lt.md )
    },
    data() {
        return {
            //leftDrawerOpen: true,
            drawerWidth: 380,
            slide: 'slide1',
            activeMenu: 'about',
            setHeightTO: null
        }
    },
    computed: {
        getDrawerOpenState() {
            return this.portfolio.getDrawerOpenState
        }
    },
    methods: {
        getPageTitles() {
            const titles = []
            
            this.portfolio.getMenuItems.forEach((v, k) => {
                titles.push({slideID: 'slide' + (k+1), title: v.name})
            })

            //console.log('title', titles)

            return titles
        },
        getMenuItems() {
            return this.portfolio.getMenuItems
        },
        setActive(id) {
            const sections = this.portfolio.getSectionObjs

            this.portfolio.getMenuItems.forEach((v) => {
                if (v.id == id) {
                    //this.slide = 'slide' + (k+1)
                    //this.activeMenu = v.id

                    //console.log(v.link, this.$refs.view.$.ctx);

                    //this.$refs.view.$.ctx.scrollToSection(v.link);
                    sections[v.link].$.ctx.scrollToThis()


                    //this.$refs.view.scrollToSection();
                }
            })
        },
        /*
        scrollToSection(id) {
            //console.log(id, this.$refs[id])

            //const el = (id == 'about-section') ? this.$refs[id].$el : this.$refs[id]

            this.noScrollEvent = true

            this.$refs[id].scrollToThis()
            
            const self = this
            setTimeout(() => {
                self.noScrollEvent = false
            }, 500)
            
        },
        */
        updateMenuAndPage(menuID, titleID) {
            this.activeMenu = menuID
            this.slide = titleID
        },
        toggleSidebar() {
            const self = this
            const sections = self.portfolio.getSectionObjs

            this.portfolio.setDrawerOpenState( !this.portfolio.getDrawerOpenState )
            //this.leftDrawerOpen = !this.leftDrawerOpen
            
            if (this.portfolio.getDrawerOpenState) {
                document.body.classList.add('has-sidebar')
            } else {
                document.body.classList.remove('has-sidebar')
            }
            
            //console.log('view', this.$refs.view.$refs['experiences-section']);

            clearTimeout(this.setHeightTO)

            //console.log('section', sections['experiences-section'].$.ctx)

            this.setHeightTO = setTimeout(() => {
                sections['experiences-section'].$.ctx.setSpacerHeight()
            }, 500)

            //return this.leftDrawerOpen
        }
    }
}
</script>