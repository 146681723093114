import script from "./Projects.vue?vue&type=script&setup=true&lang=js"
export * from "./Projects.vue?vue&type=script&setup=true&lang=js"

import "./Projects.vue?vue&type=style&index=0&id=58106817&lang=scss"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QCarousel,QBtn,QCarouselSlide,QChip,QSeparator});
