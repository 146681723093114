<script setup>
// @ is an alias to /src
import { usePortfolioStore } from '@/store/index'

import AboutMeSection from '@/components/AboutMe'
import ExperiencesSection from '@/components/Experiences'
import ProjectsSection from '@/components/Projects'
import ContactSection from '@/components/Contact'
</script>

<template>
    <AboutMeSection ref="about-section"  @update-menu-and-page="updateMenuAndPage" />

    <ExperiencesSection ref="experiences-section"  @update-menu-and-page="updateMenuAndPage" />

    <ProjectsSection ref="projects-section"  @update-menu-and-page="updateMenuAndPage" />

    <ContactSection ref="contact-section" @update-menu-and-page="updateMenuAndPage" />
</template>

<script>
export default {
    name: 'HomeView',
    emits: ['updateMenuAndPage'],
    data() {
        return {
            //noScrollEvent: false
        }
    },
    created() {
        this.portfolio = usePortfolioStore()
    },
    mounted() {
        //console.log('exp', this.$refs['experiences-section'])
        this.portfolio.setSectionObj('about-section', this.$refs['about-section'])
        this.portfolio.setSectionObj('experiences-section', this.$refs['experiences-section'])
        this.portfolio.setSectionObj('projects-section', this.$refs['projects-section'])
        this.portfolio.setSectionObj('contact-section', this.$refs['contact-section'])
    },
    methods: {
        updateMenuAndPage(id, slideID) {
            this.$emit('updateMenuAndPage', id, slideID)
        }
    }
}
</script>
