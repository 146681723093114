import script from "./AboutMe.vue?vue&type=script&setup=true&lang=js"
export * from "./AboutMe.vue?vue&type=script&setup=true&lang=js"

import "./AboutMe.vue?vue&type=style&index=0&id=f1074ac4&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QAvatar});
