<script setup>
import { usePortfolioStore } from '@/store/index'
</script>
<template>
    <div class="section" ref="projects">
        <div class="section-inner projects-section-inner">
            <div class="row">
                <div class="q-pa-md" :class="getProjectItemColClass">
                    <q-card flat bordered class="project-item text-left full-height flex column">
                        <q-card-section class="q-pa-none full-width">
                            <q-carousel
                            animated
                            v-model="projectItemSlider[0]"
                            arrows
                            navigation
                            infinite
                            transition-prev="slide-right"
                            transition-next="slide-left"
                            >
                                <template v-slot:navigation-icon="{ active, btnProps, onClick }">
                                    <q-btn v-if="active" size="xs" :icon="btnProps.icon" color="white" flat round dense @click="onClick" />
                                    <q-btn v-else size="xs" :icon="btnProps.icon" color="white" flat round dense style="opacity: 0.4;" @click="onClick" />
                                </template>
                                <q-carousel-slide :name="1" img-src="https://cdn.quasar.dev/img/mountains.jpg" />
                                <q-carousel-slide :name="2" img-src="https://cdn.quasar.dev/img/parallax1.jpg" />
                                <q-carousel-slide :name="3" img-src="https://cdn.quasar.dev/img/parallax2.jpg" />
                                <q-carousel-slide :name="4" img-src="https://cdn.quasar.dev/img/quasar.jpg" />
                            </q-carousel>
                        </q-card-section>

                        <q-card-section>
                            <div class="text-h6 q-mb-md">Marrella Flex</div>
                            Marrella Flex is a Content Management System that enables users to build and manage a business application on the internet. This application simplifies the process of content, entry screens, and report creation, allowing users to maintain a web application without needing extensive technical knowledge.
                        </q-card-section>

                        <q-card-section class="bottom-separator">
                            <q-chip color="black" text-color="white">PHP</q-chip>
                            <q-chip color="black" text-color="white">MySQL</q-chip>
                            <q-chip color="black" text-color="white">HTML</q-chip>
                            <q-chip color="black" text-color="white">JavaScript</q-chip>
                            <q-chip color="black" text-color="white">CSS</q-chip>
                        </q-card-section>
                    </q-card>
                </div>
                <div class="q-pa-md" :class="getProjectItemColClass">
                    <q-card flat bordered class="project-item text-left full-height flex column">
                        <q-card-section class="q-pa-none full-width">
                            <q-carousel
                            animated
                            v-model="projectItemSlider[1]"
                            arrows
                            navigation
                            infinite
                            transition-prev="slide-right"
                            transition-next="slide-left"
                            >
                                <template v-slot:navigation-icon="{ active, btnProps, onClick }">
                                    <q-btn v-if="active" size="xs" :icon="btnProps.icon" color="white" flat round dense @click="onClick" />
                                    <q-btn v-else size="xs" :icon="btnProps.icon" color="white" flat round dense style="opacity: 0.4;" @click="onClick" />
                                </template>
                                <q-carousel-slide :name="1" img-src="https://cdn.quasar.dev/img/mountains.jpg" />
                                <q-carousel-slide :name="2" img-src="https://cdn.quasar.dev/img/parallax1.jpg" />
                                <q-carousel-slide :name="3" img-src="https://cdn.quasar.dev/img/parallax2.jpg" />
                                <q-carousel-slide :name="4" img-src="https://cdn.quasar.dev/img/quasar.jpg" />
                            </q-carousel>
                        </q-card-section>

                        <q-card-section>
                            <div class="text-h6 q-mb-md">ERP Applications</div>
                            A complete business application that organizations use to manage and streamline various aspects of business operations. This integrates different functions and processes across an organization into a single unified system, providing real-time visibility, data consistency, and efficiency across departments. It is composed of Accounting, Human Resource, Sales, Inventory and Manufactoring-data-flow modules.
                        </q-card-section>

                        <q-card-section class="bottom-separator">
                            <q-chip color="black" text-color="white">PHP</q-chip>
                            <q-chip color="black" text-color="white">MySQL</q-chip>
                            <q-chip color="black" text-color="white">HTML</q-chip>
                            <q-chip color="black" text-color="white">JavaScript</q-chip>
                            <q-chip color="black" text-color="white">CSS</q-chip>
                            <q-chip color="black" text-color="white">Git</q-chip>
                        </q-card-section>
                    </q-card>
                </div>
                <div class="q-pa-md" :class="getProjectItemColClass">
                    <q-card flat bordered class="project-item text-left full-height flex column">
                        <q-card-section class="q-pa-none full-width">
                            <q-carousel
                            animated
                            v-model="projectItemSlider[2]"
                            arrows
                            navigation
                            infinite
                            transition-prev="slide-right"
                            transition-next="slide-left"
                            >
                                <template v-slot:navigation-icon="{ active, btnProps, onClick }">
                                    <q-btn v-if="active" size="xs" :icon="btnProps.icon" color="white" flat round dense @click="onClick" />
                                    <q-btn v-else size="xs" :icon="btnProps.icon" color="white" flat round dense style="opacity: 0.4;" @click="onClick" />
                                </template>
                                <q-carousel-slide :name="1" img-src="https://cdn.quasar.dev/img/mountains.jpg" />
                                <q-carousel-slide :name="2" img-src="https://cdn.quasar.dev/img/parallax1.jpg" />
                                <q-carousel-slide :name="3" img-src="https://cdn.quasar.dev/img/parallax2.jpg" />
                                <q-carousel-slide :name="4" img-src="https://cdn.quasar.dev/img/quasar.jpg" />
                            </q-carousel>

                            <div class="absolute-top full-width text-right q-pa-md">
                                <q-btn round unelevated class="q-mr-sm" icon="link" color="black" />
                                <q-btn round unelevated class="q-mr-sm" icon="link" color="black" />
                                <q-btn round unelevated icon="link" color="black" />
                            </div>
                        </q-card-section>

                        <q-card-section>
                            <div class="text-h6 q-mb-md">Task Organizer</div>
                            A personal web-based task management application that is used to track project tasks, time rendered, activities, and credentials efficiently. The application is tailored to the needs of a full-stack developer.
                        </q-card-section>

                        <q-card-section class="bottom-separator">
                            <q-chip color="black" text-color="white">PHP</q-chip>
                            <q-chip color="black" text-color="white">MySQL</q-chip>
                            <q-chip color="black" text-color="white">HTML</q-chip>
                            <q-chip color="black" text-color="white">Vue.js</q-chip>
                            <q-chip color="black" text-color="white">Pinia</q-chip>
                            <q-chip color="black" text-color="white">Axios</q-chip>
                            <q-chip color="black" text-color="white">SCSS</q-chip>
                            <q-chip color="black" text-color="white">Node.js</q-chip>
                            <q-chip color="black" text-color="white">Git</q-chip>
                        </q-card-section>
                    </q-card>
                </div>
                <div class="q-pa-md" :class="getProjectItemColClass">
                    <q-card flat bordered class="project-item text-left full-height flex columns">
                        <q-card-section class="q-pa-none full-width">
                            <q-carousel
                            animated
                            v-model="projectItemSlider[3]"
                            arrows
                            navigation
                            infinite
                            transition-prev="slide-right"
                            transition-next="slide-left"
                            >
                                <template v-slot:navigation-icon="{ active, btnProps, onClick }">
                                    <q-btn v-if="active" size="xs" :icon="btnProps.icon" color="white" flat round dense @click="onClick" />
                                    <q-btn v-else size="xs" :icon="btnProps.icon" color="white" flat round dense style="opacity: 0.4;" @click="onClick" />
                                </template>
                                <q-carousel-slide :name="1" img-src="https://cdn.quasar.dev/img/mountains.jpg">
                                    <div class="absolute-top full-width text-right q-pa-md">
                                        <q-btn round unelevated icon="link" color="black" />
                                    </div>
                                </q-carousel-slide>
                                <q-carousel-slide :name="2" img-src="https://cdn.quasar.dev/img/parallax1.jpg">
                                    <div class="absolute-top full-width text-right q-pa-md">
                                        <q-btn round unelevated icon="link" color="black" />
                                    </div>
                                </q-carousel-slide>
                                <q-carousel-slide :name="3" img-src="https://cdn.quasar.dev/img/parallax2.jpg">
                                    <div class="absolute-top full-width text-right q-pa-md">
                                        <q-btn round unelevated icon="link" color="black" />
                                    </div>
                                </q-carousel-slide>
                                <q-carousel-slide :name="4" img-src="https://cdn.quasar.dev/img/quasar.jpg">
                                    <div class="absolute-top full-width text-right q-pa-md">
                                        <q-btn round unelevated icon="link" color="black" />
                                    </div>
                                </q-carousel-slide>
                            </q-carousel>
                        </q-card-section>

                        <q-card-section>
                            <div class="text-h6 q-mb-md">Websites</div>
                            These are some of the websites I built as a sole developer and as a contributor. Most of them were built while working at Web Helper Australia.
                        </q-card-section>

                        <q-card-section class="bottom-separator">
                            <q-chip color="black" text-color="white">PHP</q-chip>
                            <q-chip color="black" text-color="white">MySQL</q-chip>
                            <q-chip color="black" text-color="white">HTML</q-chip>
                            <q-chip color="black" text-color="white">JavaScript</q-chip>
                            <q-chip color="black" text-color="white">CSS</q-chip>
                            <q-chip color="black" text-color="white">WordPress</q-chip>
                            <q-chip color="black" text-color="white">SCSS</q-chip>
                            <q-chip color="black" text-color="white">jQuery</q-chip>
                            <q-chip color="black" text-color="white">fullPage.js</q-chip>
                            <q-chip color="black" text-color="white">Next.js</q-chip>
                            <q-chip color="black" text-color="white">Knockout.js</q-chip>
                            <q-chip color="black" text-color="white">Bootstrap</q-chip>
                            <q-chip color="black" text-color="white">Grunt</q-chip>
                            <q-chip color="black" text-color="white">Git</q-chip>
                        </q-card-section>
                    </q-card>
                </div>
                <div class="q-pa-md" :class="getProjectItemColClass">
                    <q-card flat bordered class="project-item text-left full-height flex column">
                        <q-card-section class="q-pa-none full-width">
                            <q-carousel
                            animated
                            v-model="projectItemSlider[4]"
                            arrows
                            navigation
                            infinite
                            transition-prev="slide-right"
                            transition-next="slide-left"
                            >
                                <template v-slot:navigation-icon="{ active, btnProps, onClick }">
                                    <q-btn v-if="active" size="xs" :icon="btnProps.icon" color="white" flat round dense @click="onClick" />
                                    <q-btn v-else size="xs" :icon="btnProps.icon" color="white" flat round dense style="opacity: 0.4;" @click="onClick" />
                                </template>
                                <q-carousel-slide :name="1" img-src="https://cdn.quasar.dev/img/mountains.jpg">
                                    <div class="absolute-top full-width text-right q-pa-md">
                                        <q-btn round unelevated icon="link" color="black" />
                                    </div>
                                </q-carousel-slide>
                                <q-carousel-slide :name="2" img-src="https://cdn.quasar.dev/img/parallax1.jpg">
                                    <div class="absolute-top full-width text-right q-pa-md">
                                        <q-btn round unelevated icon="link" color="black" />
                                    </div>
                                </q-carousel-slide>
                                <q-carousel-slide :name="3" img-src="https://cdn.quasar.dev/img/parallax2.jpg">
                                    <div class="absolute-top full-width text-right q-pa-md">
                                        <q-btn round unelevated icon="link" color="black" />
                                    </div>
                                </q-carousel-slide>
                                <q-carousel-slide :name="4" img-src="https://cdn.quasar.dev/img/quasar.jpg">
                                    <div class="absolute-top full-width text-right q-pa-md">
                                        <q-btn round unelevated icon="link" color="black" />
                                    </div>
                                </q-carousel-slide>
                            </q-carousel>
                        </q-card-section>

                        <q-card-section>
                            <div class="text-h6 q-mb-md">E-Commerce Website</div>
                            These are some of the websites I built as a sole developer and as a contributor. Most of them were built while working at Web Helper Australia.
                        </q-card-section>

                        <q-card-section class="bottom-separator">
                            <q-chip color="black" text-color="white">PHP</q-chip>
                            <q-chip color="black" text-color="white">MySQL</q-chip>
                            <q-chip color="black" text-color="white">HTML</q-chip>
                            <q-chip color="black" text-color="white">JavaScript</q-chip>
                            <q-chip color="black" text-color="white">CSS</q-chip>
                            <q-chip color="black" text-color="white">WooCommerce</q-chip>
                            <q-chip color="black" text-color="white">Shopify</q-chip>
                            <q-chip color="black" text-color="white">Magento</q-chip>
                            <q-chip color="black" text-color="white">SCSS</q-chip>
                            <q-chip color="black" text-color="white">jQuery</q-chip>
                            <q-chip color="black" text-color="white">Bootstrap</q-chip>
                            <q-chip color="black" text-color="white">Grunt</q-chip>
                            <q-chip color="black" text-color="white">Git</q-chip>
                            <q-chip color="black" text-color="white">ePOSNow</q-chip>
                        </q-card-section>
                    </q-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectsSection',
    emits: ['updateMenuAndPage'],
    props: {
    },
    data() {
        return {
            projectItemSlider: [1, 1, 1, 1, 1],
            test: true
        }
    },
    created() {
        this.portfolio = usePortfolioStore()
    },
    mounted() {
        const self = this
        let scrollTO = null
        let prevScrollTop = 0

        //console.log(document.body)
        //console.log('Project is mounted')

        this.portfolio.setSectionObj('projects', self.$refs['projects'])

        //this.$nextTick()

        //---------------------------------------------------------
        //Updating side title when this section is within viewport
        //---------------------------------------------------------
        const func = () => {
            //console.log('projects')
            const scrollTop = window.scrollY

            clearTimeout(scrollTO)

            //console.log(self.scrollY)

            const sections = self.portfolio.getSectionObjs

            scrollTO = setTimeout(() => {
                //if (self.noScrollEvent) return

                const winHeight = window.innerHeight
                let top = window.scrollY
                let bottom = top + (winHeight * 0.41)
                //const sectionTop = self.$refs['projects'].offsetTop
                //const sectionHeight = self.$refs['projects'].offsetHeight
                const sectionTop = sections['projects'].offsetTop
                const sectionHeight = sections['projects'].offsetHeight
                
                const sectionBottom = sectionTop + sectionHeight
                const dir = (prevScrollTop < top) ? 'd' : 'u'

                //console.log('prevtop->', prevScrollTop, 'currtop->', top)
                //console.log(v.link, dir)

                //console.log(winHeight)
                //console.log(prevScrollTop, top, dir)
                
                if (dir == 'd') { //down
                    //console.log(sectionTop, top, bottom)

                    if (sectionTop >= top && sectionTop <= bottom) {
                        //console.log(v.name)
                        self.$emit('updateMenuAndPage', 'projects', 'slide3')
                    }
                } else { //up
                    top = (top + winHeight) - (winHeight * 0.27)
                    bottom = top + winHeight

                    if (sectionBottom >= top && sectionBottom <= bottom) {
                        //console.log(v.name)
                        self.$emit('updateMenuAndPage', 'projects', 'slide3')
                    }
                }
                //console.log(self.$refs[v.link].offsetTop)

                prevScrollTop = scrollTop
            }, 5)
        }

        window.removeEventListener('scroll', func)
        window.addEventListener('scroll', func)
    },
    computed: {
        getProjectItemColClass() {
            return this.portfolio.getDrawerOpenState ? 'col-sm-12 col-lg-6' : 'col-xs-12 col-md-6 col-lg-4'
        }
    },
    methods: {
        scrollToThis() {
            //console.log(this.$refs['projects'].innerHTML)

            const sections = this.portfolio.getSectionObjs

            this.$smoothScroll({
                scrollTo: sections['projects'],
                hash: '#projects', // required if updateHistory is true
            })
        }
    }
}
</script>

<style lang="scss">
.projects-section-inner {
    > .row {
        margin-left: -16px;
        margin-right: -16px;
    }
}

.project-item {
    border-color: #000;
    border-width: 2px;

    .bottom-separator {
        margin-top: auto;
        border-top: 2px solid #000;
    }

    .q-separator {
        background-color: rgba(0, 0, 0, 1);
    }

    .q-separator.q-separator--horizontal {
        height: 2px;
    }
}


//Medium
@media only screen and (min-width: 1024px) {
    .projects-section-inner {
        > .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
</style>