<script setup>
import { usePortfolioStore } from '@/store/index'
import { Screen } from 'quasar'
</script>

<template>
    <div class="section q-pa-none" ref="experiences" style="position: relative;">
        <div class="section-inner" style="height: 100%;">

        <div class="vertical-spacer"></div>

        <div class="experiences-wrapper">
            <div class="experiences-wrapper-inner flex column justify-center">

                <div class="experiences">
                    <div class="items-wrapper">
                        <q-card flat bordered class="my-card" :class="Screen.lt.md ? '' : 'q-mr-xl'" v-for="(e, index) in expItems" :key="index" :style="(typeof e.width === 'undefined' || Screen.lt.md) ? '' : `width: ${e.width};`">
                            <q-card-section horizontal class="exp-topbar items-center">
                                <q-card-section class="exp-date col-8 q-py-none q-pl-md text-left">
                                    <template v-for="(d, index) in e.dates" :key="index">
                                        <span>{{ d }}</span>
                                        <span class="q-mx-sm" v-if="index < (e.dates.length - 1)">&#9679;</span>
                                    </template>
                                </q-card-section>

                                <q-card-section class="col-4 q-py-none q-pr-md text-right">
                                    <q-badge rounded color="red" text-color="white" label="CURRENT" v-if="index == (expItems.length - 1)"></q-badge>
                                </q-card-section>
                            </q-card-section>

                            <q-separator style="height: 2px; background-color: #000;" />
                            
                            <q-item class="exp-header q-py-md">
                                <q-item-section class="text-left">
                                    <q-item-label class="exp-pos">{{ e.position }}</q-item-label>
                                    <q-item-label class="exp-comp">
                                        {{ e.company }}
                                        <q-badge outline class="q-ml-sm" color="black" label="PART-TIME" v-if="!e.fulltime" />
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section class="col-1">
                                    <q-icon size="lg" name="o_business_center"></q-icon>
                                </q-item-section>
                            </q-item>

                            <q-separator v-if="!Screen.lt.md" style="height: 2px; background-color: #000;" />

                            <q-card-section :horizontal="!Screen.lt.md" class="exp-body" :class="Screen.lt.md ? 'q-pa-none' : ''">
                                <q-card-section class="text-left">
                                    {{ e.desc }}
                                </q-card-section>

                                <q-separator v-if="!Screen.lt.md" vertical style="width: 2px; background-color: #000;" />
                                <q-separator v-else horizontal style="height: 2px; background-color: #000;" />

                                <q-card-section class="text-left" :class="(typeof e.toolCol === 'undefined') ? 'col-5' : e.toolCol">
                                    <q-chip color="black" text-color="white" v-for="(t, index) in e.tools" :key="index">{{ t }}</q-chip>
                                </q-card-section>
                            </q-card-section>
                        </q-card>

                        <div class="card-spacer"></div>
                    </div>
                </div>

            </div>
        </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'ExperiencesSection',
    emits: ['updateMenuAndPage'],
    props: {
    },
    data() {
        return {
            scroll: false,
            expItems: [
                {company: 'Clear Export Industries, Inc.', 
                position: 'Lead Software Engineer / Systems Admin', 
                dates: ['Jan 2008 — Jan 2019'], 
                fulltime: true,
                desc: 'Rebuilt the company\'s ERP application on a web-based platform, transitioning from a desktop application to a web-based application. Revamped the network infrastructure. Changed server OS to Linux and implemented virtualization. Migrated email system from Windows-based to Linux-based.', 
                tools: [
                    'PHP',
                    'MySQL',
                    'FoxPro',
                    'MSSQL',
                    'Git',
                    'JavaScript',
                    'HTML',
                    'CSS',
                    'WordPress',
                    'Microsoft Exchange',
                    'Zimbra',
                    'Proxmox'
                ]},
                {company: 'eWave (Hong Kong)', 
                position: 'Frontend Developer', 
                dates: ['Aug 2018 — Sep 2018'],  
                fulltime: false,
                desc: 'Built e-commerce pages on the Magento platform in a collaborative setup using Bitbucket. Set up a Badiu map for the Store Locator page.', 
                tools: [
                    'PHP',
                    'MySQL',
                    'Magento',
                    'Knockout.js',
                    'LESS',
                    'JavaScript',
                    'HTML',
                    'CSS',
                    'Git'
                ]},
                {company: 'HK ISL (Hong Kong)', 
                position: 'Software Engineer', 
                dates: ['Apr 2022 — Oct 2022', 'Jun 2023 — Aug 2023'], 
                fulltime: false, 
                desc: 'Maintained one of the company\'s products - a Sales and Inventory system. Developed transaction and report modules for this Sales and Inventory system.', 
                tools: [
                    'Coldfusion',
                    'Lucee',
                    'Sybase',
                    'PostgreSQL',
                    'Crystal Reports',
                    'Git',
                    'SCSS',
                    'JavaScript',
                    'HTML',
                    'CSS',
                    'Bootstrap',
                    'jQuery',
                    'Coldbox'        
                ]},
                {company: 'Web Helper Australia', 
                position: 'Web Developer', 
                dates: ['Mar 2019 — Oct 2023'], 
                fulltime: true, 
                desc: 'Maintained more than 20 websites. Developed WordPress and e-commerce websites. Built website pages from scratch based on a mockup design. Migrate websites from one server to another. Developed email templates.', 
                tools: [
                    'PHP',
                    'MySQL',
                    'JavaScript',
                    'HTML',
                    'CSS',
                    'Git',
                    'SCSS',
                    'WordPress',
                    'Joomla',
                    'Wix',
                    'WooCommerce',
                    'Shopify',
                    'Square',
                    'EposNow',
                    'Bootstrap',
                    'jQuery',
                    'Node.js',
                    'Vue.js',
                    'Next.js',
                    'cPanel',
                    'Figma'
                ],
                width: '600px',
                toolCol: 'col-6'
                },
                {company: 'Benchmarking Data & Research', 
                position: 'Software Developer', 
                dates: ['Nov 2023 — Present'], 
                fulltime: true, 
                desc: 'Maintained more than 20 websites. Developed WordPress and e-commerce websites. Built website pages from scratch based on a mockup design. Migrate websites from one server to another. Developed email templates.', 
                tools: [
                    'Vue.js',
                    'Quasar',
                    'TensorFlow.js',
                    'Node.js',
                    'JavaScript',
                    'HTML',
                    'CSS',
                    'Git',
                    'SCSS',
                    'Bootstrap',
                    'jQuery',
                    'PHP',
                    'MySQL',
                    'WordPress',
                    'Laravel'
                ]
                }
            ]
        }
    },
    created() {
        this.portfolio = usePortfolioStore()
    },
    mounted() {
        const self = this
        let scrollTO = null
        let prevScrollTop = 0
        let prevScrollTop2 = 0

        this.portfolio.setSectionObj('experiences', self.$refs['experiences'])

        const mainCont = self.$refs['experiences']
        const expItemsWrapper = mainCont.querySelector('.items-wrapper')

        this.setSpacerHeight()

        //console.log(document.body)

        //---------------------------------------------------------
        //Updating side title when this section is within viewport
        //---------------------------------------------------------
        window.addEventListener('scroll', function() {
            const scrollTop = window.scrollY

            clearTimeout(scrollTO)

            //console.log(self.scrollY)

            const sections = self.portfolio.getSectionObjs

            scrollTO = setTimeout(() => {
                //if (self.noScrollEvent) return

                const winHeight = window.innerHeight
                let top = window.scrollY
                let bottom = top + (winHeight * 0.41)
                const sectionTop = sections['experiences'].offsetTop
                const sectionHeight = sections['experiences'].offsetHeight
                const sectionBottom = sectionTop + sectionHeight
                const dir = (prevScrollTop < top) ? 'd' : 'u'

                //console.log('prevtop->', prevScrollTop, 'currtop->', top)
                //console.log(v.link, dir)

                //console.log(winHeight)
                //console.log(prevScrollTop, top, dir)
                
                if (dir == 'd') { //down
                    //console.log(sectionTop, top, bottom)

                    if (sectionTop >= top && sectionTop <= bottom) {
                        //console.log(v.name)
                        self.$emit('updateMenuAndPage', 'experiences', 'slide2')
                    }
                } else { //up
                    top = (top + winHeight) - (winHeight * 0.27)
                    bottom = top + winHeight

                    if (sectionBottom >= top && sectionBottom <= bottom) {
                        //console.log(v.name)
                        self.$emit('updateMenuAndPage', 'experiences', 'slide2')
                    }
                }
                //console.log(self.$refs[v.link].offsetTop)

                prevScrollTop = scrollTop
            }, 5)
        })

        //let expMaxScrollValue = 0

        //-------------------------------------
        //Enable section horizontal scrolling
        //-------------------------------------
        window.addEventListener('scroll', function() {
            if (!self.scroll) return
            if (Screen.lt.md) return

            const scrollTop = window.scrollY

            const winHeight = window.innerHeight
            let top = window.scrollY
            //let bottom = top + winHeight
            const sectionTop = mainCont.offsetTop
            const sectionHeight = mainCont.offsetHeight
            const sectionBottom = sectionTop + sectionHeight
            const dir = (prevScrollTop2 < top) ? 'd' : 'u'

            const innerSectionTop = sectionBottom - winHeight

            const expSection = mainCont.querySelector('.experiences-wrapper')

            //console.log(sectionTop, scrollTop)

            //console.log(expSection)

            if (dir == 'd') {
                if (sectionTop > top) {
                    //Absolute section - top
                    expSection.classList.remove('sticky')
                    expSection.classList.remove('bottom')
                } else if (sectionTop <= top && innerSectionTop > top) {
                    //Fix section
                    expSection.classList.add('sticky')
                    expSection.classList.remove('bottom')

                    const scrollStep = top - sectionTop
                    //console.log('scrollstep d', scrollStep)

                    expItemsWrapper.style.left = '-' + scrollStep + 'px'
                    //expMaxScrollValue = (scrollStep > expExcessWidth) ? expExcessWidth : scrollStep
                    //expMaxScrollValue = scrollStep

                } else if (innerSectionTop <= top) {
                    //Absolute section - bottom
                    expSection.classList.add('bottom')
                    expSection.classList.remove('sticky')
                }
            } else { //up
                if (innerSectionTop < top) {
                    //Absolute section - bottom
                    expSection.classList.add('bottom')
                    expSection.classList.remove('sticky')
                } else if (innerSectionTop >= top && sectionTop < top ) {
                    //Fix section
                    expSection.classList.add('sticky')
                    expSection.classList.remove('bottom')

                    //const scrollStep = innerSectionTop - top
                    const scrollStep = top - sectionTop
                    //console.log('scrollstep u', scrollStep)

                    //const scrolled = Math.max(expMaxScrollValue, expExcessWidth)
                    //expItemsWrapper.style.left = '-' + (scrolled - scrollStep) + 'px'
                    //const scrolledBack = expMaxScrollValue - scrollStep
                    //console.log('scrolled', scrolledBack)
                    //const scrolled = (scrolledBack < 0) ? 0 : (-1 * scrolledBack)
                    expItemsWrapper.style.left = '-' + scrollStep + 'px'
                    //expMaxScrollValue -= scrollStep

                } else if (sectionTop > top) {
                    //Absolute section - top
                    expSection.classList.remove('sticky')
                    expSection.classList.remove('bottom')

                    if (expItemsWrapper.offsetLeft < 0) {
                        expItemsWrapper.style.left = '0px'
                    }
                    //console.log('remaining space:', expMaxScrollValue)
                    //console.log('wrapper left:', expItemsWrapper.offsetLeft)
                }
            }

            prevScrollTop2 = scrollTop
        })
        
        let resizeTO = null

        window.addEventListener('resize', function() {
            clearTimeout(resizeTO)

            resizeTO = setTimeout(() => {
                self.setSpacerHeight()
            }, 50)
        })
    },
    methods: {
        scrollToThis() {
            const sections = this.portfolio.getSectionObjs

            this.$smoothScroll({
                scrollTo: sections['experiences'],
                hash: '#experiences', // required if updateHistory is true
            })
        },
        setSpacerHeight() {
            const sections = this.portfolio.getSectionObjs
            const mainCont = sections['experiences']
            const expWrapper = mainCont.querySelector('.experiences')

            //console.log(mainCont, expWrapper)
            const expItemsWrapper = mainCont.querySelector('.items-wrapper')
            const expSpacer = mainCont.querySelector('.vertical-spacer')

            this.scroll = false

            if (Screen.lt.md) {
                expSpacer.style.height = 'auto'
            } else {
                let expExcessWidth = expItemsWrapper.offsetWidth - expWrapper.offsetWidth
                expExcessWidth = (expExcessWidth < 0) ? 0 : expExcessWidth

                const spacerHeight = window.innerHeight + expExcessWidth

                if (expExcessWidth > 0) this.scroll = true

                console.log(this.scroll)
                console.log('spacer height:', spacerHeight)

                //Set the Spacer height
                expSpacer.style.height = spacerHeight + 'px'
            }
        }
    }
}
</script>

<style lang="scss">
.experiences-wrapper {
    //transition: top 0.5s ease-in-out;

    .experiences-wrapper-inner {
        //margin: 0 50px;
    }

    .experiences {
        //position: relative;
        
        .items-wrapper {
            /*transition: left 0.2s ease-in-out;*/
        }

        .items-wrapper > .q-card {
            //display: inline-block;
            //width: 550px;
            //height: 100%;
        }

        .items-wrapper > .q-card:first-child {
        }

        .card-spacer {
        }
    }

    .q-card {
        margin-bottom: 40px;
        white-space: normal;
        border: 2px solid #000;
        border-bottom-width: 4px;
        border-radius: 15px;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: scale(1.06);
            //border-style: dashed;
            border-bottom-width: 2px;
            box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3) !important;
        }
    }

    .q-card .exp-topbar {
        //height: 40px;
        padding: 7px 0;
        background-color: $black;
        color: $white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;

        .exp-date {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
        }
    }

    .q-card .exp-header {
        //height: 78px;
        background-color: #ffffff;
        border: 2px solid $white;
        transition: transform 0.3s ease-in-out 0.2s, border-color 0.5s ease-in-out;

        > .q-item__section {
            flex-direction: row;
            justify-content: start;
        }

        .exp-pos {
            width: 100%;
            font-weight: 700;
            font-size: 20px;
        }

        .exp-comp {
            width: 100%;
            font-weight: 600;
            color: #555;
        }
    }

    .q-card .exp-body {
        //height: calc(100% - 121px);

        .q-chip {
            font-size: 13px;
            font-weight: 600;
        }
    }

    .q-card:hover .exp-header {
        transform: scale(1.05);
        transform-origin: center;
        //box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        //border: 2px solid $black;
        border-color: $black;
    }

    .q-card:hover .exp-pos {
        color: #f44336;
    }
}

.has-sidebar .experiences-wrapper.sticky {
}

//Medium
@media only screen and (min-width: 1024px) {
    .experiences-wrapper {
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;

        //transition: top 0.5s ease-in-out;

        &.sticky {
            position: fixed;
            width: calc(100% - $page_title_width);
            top: 0;
            left: $page_title_width;
        }

        &.bottom {
            top: auto;
            bottom: 0;
        }

        .experiences-wrapper-inner {
            position: relative;
            height: 100%;
            margin: 0 50px;
            overflow: hidden;
        }

        .experiences {
            position: relative;
            //overflow: hidden;
            //width: calc(100% - 100px);
            width: 100%;
            height: 490px;
            //margin-left: 50px;
            //margin-right: 50px;

            .items-wrapper {
                position: absolute;
                width: auto;
                height: 100%;
                white-space: nowrap;
                top: 0;
                left: 0;

                /*transition: left 0.2s ease-in-out;*/
            }

            .items-wrapper > .q-card {
                display: inline-block;
                width: 550px;
                height: 100%;
            }

            .items-wrapper > .q-card:first-child {
                margin-left: 100px;
            }

            .card-spacer {
                display: inline-block;
                width: 80px;
            }
        }

        .q-card {
            margin-bottom: 0;
        }

        .q-card .exp-topbar {
            height: 40px;
            padding: 0;
        }

        .q-card .exp-header {
            height: 78px;

            > .q-item__section {
                //flex-direction: row;
                //justify-content: start;
            }
        }

        .q-card .exp-body {
            height: calc(100% - 121px);
        }
    }

    .has-sidebar .experiences-wrapper.sticky {
        width: calc(100% - (380px + $page_title_width));
        left: calc(380px + $page_title_width);
    }
}
</style>