import script from "./Experiences.vue?vue&type=script&setup=true&lang=js"
export * from "./Experiences.vue?vue&type=script&setup=true&lang=js"

import "./Experiences.vue?vue&type=style&index=0&id=7004bd1d&lang=scss"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QBadge,QSeparator,QItem,QItemSection,QItemLabel,QIcon,QChip});
