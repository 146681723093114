<script setup>
import { usePortfolioStore } from '@/store/index'
</script>
<template>
    <div class="section height-100" ref="contact">
        <div class="section-inner contact-section-inner absolute-top flex column justify-center content-center">
            <div class="text-center">
                Do you have amazing ideas right now that you want to build with me? :) Just give me a buzz through this email: dev@alrexconsus.com
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactSection',
    emits: ['updateMenuAndPage'],
    props: {
    },
    data() {
        return {}
    },
    created() {
        this.portfolio = usePortfolioStore()
    },
    mounted() {
        const self = this
        let scrollTO = null
        let prevScrollTop = 0

        //console.log(document.body)
        this.portfolio.setSectionObj('contact', self.$refs['contact'])

        //---------------------------------------------------------
        //Updating side title when this section is within viewport
        //---------------------------------------------------------
        window.addEventListener('scroll', function() {
            //console.log('contact')

            const scrollTop = window.scrollY

            clearTimeout(scrollTO)

            //console.log(self.scrollY)

            const sections = self.portfolio.getSectionObjs

            scrollTO = setTimeout(() => {
                //if (self.noScrollEvent) return

                const winHeight = window.innerHeight
                let top = window.scrollY
                let bottom = top + (winHeight * 0.41)
                const sectionTop = sections['contact'].offsetTop
                const sectionHeight = sections['contact'].offsetHeight
                const sectionBottom = sectionTop + sectionHeight
                const dir = (prevScrollTop < top) ? 'd' : 'u'

                //console.log('prevtop->', prevScrollTop, 'currtop->', top)
                //console.log(v.link, dir)

                //console.log(winHeight)
                //console.log(prevScrollTop, top, dir)
                
                if (dir == 'd') { //down
                    //console.log(sectionTop, top, bottom)

                    if (sectionTop >= top && sectionTop <= bottom) {
                        //console.log(v.name)
                        self.$emit('updateMenuAndPage', 'contact', 'slide4')
                    }
                } else { //up
                    top = (top + winHeight) - (winHeight * 0.27)
                    bottom = top + winHeight

                    if (sectionBottom >= top && sectionBottom <= bottom) {
                        //console.log(v.name)
                        self.$emit('updateMenuAndPage', 'contact', 'slide4')
                    }
                }
                //console.log(self.$refs[v.link].offsetTop)

                prevScrollTop = scrollTop
            }, 5)
        })
    },
    methods: {
        scrollToThis() {
            //console.log(this.$refs['contact'].innerHTML)

            const sections = this.portfolio.getSectionObjs

            this.$smoothScroll({
                scrollTo: sections['contact'],
                hash: '#contact', // required if updateHistory is true
            })
        }
    }
}
</script>

<style lang="scss">
.contact-section-inner {
    height: calc(100% - 100px);
    top: 50px;

    > div {
        padding: 50px 30px;
        max-width: 600px;
        font-size: 1.15rem; font-weight: 300;
    }
}
</style>