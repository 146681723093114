<script setup>
import { usePortfolioStore } from '@/store/index'
</script>
<template>
    <div class="section height-100" ref="about">
        <div class="section-inner about-section-inner">
            <div class="row">
                <div class="col-xs-12 col-md-3 text-center">
                    <!--<q-btn round class="q-ma-xl" size="xl" color="black" icon="shopping_cart" />-->
                    <q-avatar size="150px">
                        <img src="/portfolio-photo.jpg">
                    </q-avatar>
                </div>
                <div class="col-xs-12 col-md-9 text-center">
                    <div class="about-content text-left q-mx-auto">
                        <h3 class="q-mt-none" style="font-weight: 500;">I'm a full-stack developer with honed adaptability skills and a strong desire to learn.</h3>

                        I have been passionately crafting anything web since 2006, a journey that began after I graduated from college. Starting as a <strong style="font-weight: 600;">Software Engineer</strong> that develops web-based applications and transitioning into a <strong style="font-weight: 600;">Website Developer</strong> along the way, my career evolution has significantly enriched my skill set, ultimately shaping me into the Full-stack Web Developer I am today.

                        Moreover, my diverse experience in <strong style="font-weight: 600;">E-commerce</strong> and <strong style="font-weight: 600;">Server Administration</strong> has further enriched my skill set as a Developer. This multifaceted background has honed my ability to create seamless digital experiences and overcome technical challenges.

                        I am also fervently dedicated to staying at the forefront of technology trends, continuously fine-tuning my skills and staying current with the latest advancements. With this commitment and my comprehensive skill set, I am fully equipped to bring your dream web application or an interactive and elegant website to life.
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutMeSection',
    emits: ['updateMenuAndPage'],
    props: {
    },
    data() {
        return {}
    },
    created() {
        this.portfolio = usePortfolioStore()
    },
    mounted() {
        const self = this
        let scrollTO = null
        let prevScrollTop = 0

        //console.log(document.body)

        this.portfolio.setSectionObj('about', self.$refs['about'])

        //---------------------------------------------------------
        //Updating side title when this section is within viewport
        //---------------------------------------------------------
        window.addEventListener('scroll', function() {
            //console.log('about')
            const scrollTop = window.scrollY

            clearTimeout(scrollTO)

            //console.log(self.scrollY)

            const sections = self.portfolio.getSectionObjs

            scrollTO = setTimeout(() => {
                //if (self.noScrollEvent) return

                const winHeight = window.innerHeight
                let top = window.scrollY
                let bottom = top + (winHeight * 0.41)
                const sectionTop = sections['about'].offsetTop
                const sectionHeight = sections['about'].offsetHeight
                const sectionBottom = sectionTop + sectionHeight
                const dir = (prevScrollTop < top) ? 'd' : 'u'

                //console.log('prevtop->', prevScrollTop, 'currtop->', top)
                //console.log(v.link, dir)

                //console.log(winHeight)
                //console.log(prevScrollTop, top, dir)
                
                if (dir == 'd') { //down
                    //console.log(sectionTop, top, bottom)

                    if (sectionTop >= top && sectionTop <= bottom) {
                        //console.log(v.name)
                        self.$emit('updateMenuAndPage', 'about', 'slide1')
                    }
                } else { //up
                    top = (top + winHeight) - (winHeight * 0.27)
                    bottom = top + winHeight

                    if (sectionBottom >= top && sectionBottom <= bottom) {
                        //console.log(v.name)
                        self.$emit('updateMenuAndPage', 'about', 'slide1')
                    }
                }
                //console.log(self.$refs[v.link].offsetTop)

                prevScrollTop = scrollTop
            }, 5)
        })
    },
    methods: {
        scrollToThis() {
            const sections = this.portfolio.getSectionObjs

            this.$smoothScroll({
                scrollTo: sections['about'],
                hash: '#about', // required if updateHistory is true
            })
        }
    }
}
</script>

<style lang="scss">
.about-section-inner {
    padding: 0 30px;

    .about-content {
        max-width: 600px;
        font-size: 1.15rem;
        font-weight: 300;
    }
}

//Small
@media only screen and (min-width: 600px) {
    .about-section-inner {
        padding: 0 50px;

        .about-content {
            //font-size: 1.15rem;
            //font-weight: 300;
        }
    }
}

//Medium
@media only screen and (min-width: 1024px) {
    .about-section-inner {
        padding: 50px;
    }
    
    .row > .col-xs-12:first-child {
        order: 1000;
    }

    .row > .col-xs-12:last-child {
        order: 1;
    }
}
</style>