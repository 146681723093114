import { defineStore } from 'pinia'

export const usePortfolioStore = defineStore('counter', {
  state: () => {
    return {
      menuItems: [
        {id: 'about', name: 'About', link: 'about-section'},
        {id: 'experiences', name: 'Experience', link: 'experiences-section'},
        {id: 'projects', name: 'Projects', link: 'projects-section'},
        {id: 'contact', name: 'Contact', link: 'contact-section'}
      ],
      drawerOpen: true,
      sections: [],
    }
  },
  getters: {
    getMenuItems: (state) => state.menuItems,
    getDrawerOpenState: (state) => state.drawerOpen,
    getSectionObjs: (state) => state.sections,
  },
  actions: {
    setDrawerOpenState(state) {
      this.drawerOpen = state
    },
    setSectionObj(index, value) {
      this.sections[index] = value

      //console.log('section', this.sections)
    },
  },
})
